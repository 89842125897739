export default function ({ app, redirect, route, error: errorPage, $config }) {
  const jwtSession = app.$cookies.get(app.$config.ZEIT_SSO_SESSION_COOKIE)
  const jwtMaster = app.$cookies.get(app.$config.ZEIT_SSO_MASTER_COOKIE)

  if (jwtMaster && jwtSession) {
    return app.$apolloHelpers
      .onLogin(jwtSession)
      .then(() => {
        return app.store.dispatch('user/setEmail')
      })
      .catch(error => {
        if (
          error.graphQLErrors &&
          error.graphQLErrors.some(({ message }) => message === 'unauthorized')
        ) {
          return redirect(ssoSessionUrl(route, $config))
        } else if (
          error.graphQLErrors &&
          error.graphQLErrors.some(({ message }) => message === 'revoked')
        ) {
          return app.$apolloHelpers.onLogout().then(() => {
            return app.store.dispatch('user/unsetEmail')
          })
        } else {
          errorPage({ statusCode: 500 })
          return true
        }
      })
  } else if (jwtMaster) {
    return redirect(ssoSessionUrl(route, $config))
  } else {
    if (jwtSession) {
      app.context.$sentry.captureMessage(
        'Session Cookie present but NO Master Cookie'
      )
    }
    return app.$apolloHelpers
      .onLogout()
      .then(() => {
        return app.store.dispatch('user/unsetEmail')
      })
      .catch(() => {
        errorPage({ statusCode: 500 })
      })
  }
}

export function ssoSessionUrl(route, $config) {
  const redirectTarget = new URL($config.PRODUCTION_URL)
  redirectTarget.pathname = route.fullPath
  const redirectUrl = new URL($config.ZEIT_SSO_SESSION_URL)
  redirectUrl.searchParams.append(
    'url',
    decodeURIComponent(redirectTarget.toString())
  )
  redirectUrl.searchParams.append('entry_service', 'boa_bot')

  return redirectUrl.toString()
}
