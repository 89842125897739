<template>
  <div>
    <svg-sprite-icons style="display: none" />
    <div class="layout__default">
      <TopBar />
      <MenuBar v-if="authenticated && onProfile" />
      <div class="container" :class="{ blurred: isNavOpen }">
        <nuxt />
      </div>
      <Footer />
    </div>
    <GoogleTagManager />
    <WebtrekkPixel />
    <Sourcepoint />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import TopBar from '@/components/containers/TopBar.vue'
import MenuBar from '@/components/containers/MenuBar.vue'
import Footer from '@/components/containers/Footer.vue'
import WebtrekkPixel from '@/components/tracking/WebtrekkPixel.vue'
import Sourcepoint from '@/components/consent/Sourcepoint.vue'
import GoogleTagManager from '@/components/tracking/GoogleTagManager.vue'

export default {
  components: {
    TopBar,
    MenuBar,
    Footer,
    WebtrekkPixel,
    GoogleTagManager,
    Sourcepoint
  },
  middleware: ['user-state'],
  data() {
    return {
      profilePaths: ['jobs', 'suchprofil', 'testergebnis', 'einstellungen']
    }
  },
  head: () => ({
    title: 'BOA Berufstest | Powered by ZEIT Campus',
    meta: [
      // hid is used as unique identifier. Do not use `vmid` for it as it will not work
      {
        hid: 'description',
        name: 'description',
        content:
          'Entdecke deine Stärken und bekomme Jobs per Mail ➤ Starte die Jobsuche mit dem BOA Berufstest von ZEIT Campus.'
      }
    ]
  }),
  computed: {
    ...mapGetters('app', ['isNavOpen']),
    ...mapGetters('user', ['email']),
    authenticated() {
      return this.email !== ''
    },
    onProfile() {
      const basename = this.$nuxt.$route.path.split('/')[1]
      return this.profilePaths.includes(basename)
    }
  }
}
</script>

<style scoped lang="scss">
.container {
  // TODO: clean this up
  // max-width: 1024px;
  // margin: 0 auto spacing(8);
  // min-height: empirically determined value for performance reasons
  // min-height: 75vh;
  background: color(porcelain-light);
}

.container.blurred {
  @include blur;
}
</style>
